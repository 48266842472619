import { Component, OnInit } from '@angular/core';
import { FahrtenbuchService } from '../fahrtenbuch.service';

@Component({
  selector: 'app-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.css']
})
export class MembersComponent implements OnInit {
  
  constructor(private _fahrtenbuchService: FahrtenbuchService) { }

  ngOnInit() {

  }
}
