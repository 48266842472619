<div class="fix-footer">
<div class="container-fluid px-0">
  <div
    id="myCarousel"
    class="carousel slide"
    data-ride="carousel"
    data-interval="5000"
  >
    <ol class="carousel-indicators">
      <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
      <li data-target="#myCarousel" data-slide-to="1"></li>
      <li data-target="#myCarousel" data-slide-to="2"></li>
    </ol>
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img
          class="d-block w-100 img-height"
          src="assets\img\1600x500\castro_fasti.jpg"
          alt="Leopard"
        />
        <div class="carousel-caption d-none d-sm-block">
          <h5></h5>
          <small> </small>
        </div>
      </div>
      <div class="carousel-item">
        <img
          class="d-block w-100"
          src="assets\img\1600x500\kanadier_fisti.jpg"
          alt="Cat"
        />
        <div class="carousel-caption d-none d-sm-block">
          <h5></h5>
          <small> </small>
        </div>
      </div>
      <div class="carousel-item">
        <img
          class="d-block w-100"
          src="assets\img\1600x500\castro_stefan.jpg"
          alt="Lion"
        />
        <div class="carousel-caption d-none d-sm-block">
          <h5></h5>
          <small> </small>
        </div>
      </div>
    </div>
    <a
      class="carousel-control-prev"
      href="#myCarousel"
      role="button"
      data-slide="prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a
      class="carousel-control-next"
      href="#myCarousel"
      role="button"
      data-slide="next"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
</div>

<div class="container pt-3 text-center">
  <div class="page-header">
    <h4>
      Wir freuen uns über Ihren Besuch auf unserer Website und heißen Sie
      herzlich willkommen!
    </h4>
  </div>
  <p>
    Der Welser Faltbootverein ist ein Verein für Kajak- und KanufahrerInnen, die
    gerne Zeit am Wildwasser oder Flachwasser verbringen.
  </p>
  <!--div class="d-sm-none d-md-none d-lg-none d-xl-none">
      TEST
  </div>

  <app-sponsor-carousel class="d-none d-sm-block"></app-sponsor-carousel -->
  <app-sponsor-carousel></app-sponsor-carousel>
</div>
</div>
