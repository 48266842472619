import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-sponsor-carousel',
  templateUrl: './sponsor-carousel.component.html',
  styleUrls: ['./sponsor-carousel.component.css']
})
export class SponsorCarouselComponent implements OnInit {

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    items: 5,
    margin:100,
    autoplay:true,
    smartSpeed: 1200,
    autoplayHoverPause:true,
    navText: ['', ''],
    animateOut: true,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    }
  }

  images = [
    {
      link: 'https://www.kanuverband.at/',
      src: 'assets/sponsors/logo1.png'
    },
    {
      link: 'http://www.gartenundteich.at/',
      src: 'assets/sponsors/logo2.png'
    },
    {
      link: 'https://servustv.com/bewegdich',
      src: 'assets/sponsors/servus1.png'
    },
    {
      link: 'https://www.sobos.at/app_download.html',
      src: 'assets/sponsors/Pegelalarm_Logo.png'
    },
    {
      link: 'https://www.kko-alpinsport.eu/',
      src: 'assets/sponsors/logo6.png',
    },
    {
      link: 'https://www.eww.at/',
      src: 'assets/sponsors/logo3.png'
    },
    {
      link: 'https://www.s-leasing.at',
      src: 'assets/sponsors/logo4.png'
    },
  ];

  constructor(){
  }
  ngOnInit() {
  }
}
