<div class="pt-3" style="text-align:center">
  <owl-carousel-o [options]="customOptions">
    <ng-container *ngFor="let image of this.images;">
      <ng-template carouselSlide>
        <a href="{{image.link}}" target="_blank">
          <img src="{{image.src}}">
        </a>
      </ng-template>
    </ng-container>
  </owl-carousel-o>
</div>
