import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { FahrtenbuchService } from "../fahrtenbuch.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Router } from "@angular/router";
import { AuthService } from "../auth.service";
import { NgForm } from "@angular/forms";
import { Fluss } from "./fluss.model";
import { Fahrt } from "./fahrt.model";
import { coutries } from '../../assets/countries.json';

@Component({
  selector: "app-fahrtenbuch",
  templateUrl: "./fahrtenbuch.component.html",
  styleUrls: ["./fahrtenbuch.component.css"]
})
export class FahrtenbuchComponent implements OnInit {
  @ViewChild("selectpicker") selpick: ElementRef;
  @ViewChild("fahrtEintragen") formFahrtEintragen: NgForm;
  @ViewChild("flussEintragen") formFlussEintragen: NgForm;

  private _year: number = new Date().getFullYear();
  alleFahrten: Fahrt[];
  paddlerFahrten: Fahrt[];
  countryData = [];
  riverData = [];
  kmRangliste = [];
  flussRangliste = [];
  years = [];
  alleFahrtenDatum = {
    vonDatum: "",
    bisDatum: ""
  };
  meineFahrtenDatum = {
    vonDatum: "",
    bisDatum: ""
  };
  levels = ["cm", "m3"];
  fahrtData: Fahrt;
  flussData: Fluss;
  kmRanglisteJahr: number = this._year;
  flussRanglisteJahr: number = this._year;
  searchText: string;
  emptyArray: boolean = false;
  userMessage = "";
  submitted = false;
  flussSavedOk = false;
  flussSavedError = false;
  fahrtSavedOk = false;
  fahrtSavedError = false;
  fahrtDeletedOk = false;
  fahrtDeletedError = false;
  constructor(
    private _fahrtenbuchService: FahrtenbuchService,
    private _router: Router,
    private _auth: AuthService
  ) {
    this.fahrtData = new Fahrt(
      "",
      "",
      "",
      0,
      0,
      "",
      "",
      this._auth.getUserName()
    );
    this.flussData = new Fluss("", "", this._auth.getUserName());
  }

  ngOnInit() {
    this.countryData = coutries;
    this.setYears();
    this.getFlussListe();
  }

  setYears() {
    for (let i = 2002; i <= this._year; i++) {
      let year = i;
      let obj = { year: year };
      this.years.unshift(obj);
    }
  }

  onYearChangeKm(jahr: number) {
    this.kmRanglisteJahr = jahr;
    this.getKmRanking();
  }

  onYearChangeHit(jahr: number) {
    this.flussRanglisteJahr = jahr;
    this.getFlussRangliste();
  }

  postFahrtSpeichern(form: NgForm) {
    console.log(this.formFahrtEintragen.value.fluss);
    console.log('Von: ' + this.formFahrtEintragen.value.vonDatum);
    console.log('Bis: ' + this.formFahrtEintragen.value.bisDatum);
    console.log(this.formFahrtEintragen.value.kilometer);
    console.log(this.formFahrtEintragen.value.pegel);
    console.log(this.formFahrtEintragen.value.level);
    console.log(this.formFahrtEintragen.value.kommentar);

    const fahrt = new Fahrt(
      this.formFahrtEintragen.value.fluss,
      this.formFahrtEintragen.value.vonDatum,
      this.formFahrtEintragen.value.bisDatum,
      this.formFahrtEintragen.value.kilometer,
      this.formFahrtEintragen.value.pegel,
      this.formFahrtEintragen.value.level,
      this.formFahrtEintragen.value.kommentar,
      this._auth.getUserName()
    );
    this._fahrtenbuchService.postFahrt(fahrt).subscribe(data => {
      if (data.ok) {
        this.fahrtSavedOk = true;
        this.formFahrtEintragen.reset();
      } else {
        this.fahrtSavedError = true;
      }
    });
    console.log(this.fahrtData);
  }

  postFlussSpeichern(form: NgForm) {
    this._fahrtenbuchService.postFluss(this.flussData).subscribe(data => {
      if (data.ok) {
        this.flussSavedOk = true;
        this.formFlussEintragen.reset();
      } else {
        this.flussSavedError = true;
      }
    });
  }

  validateDatum(): boolean {
    if (
      this.formFahrtEintragen?.value.vonDatum >
      this.formFahrtEintragen?.value.bisDatum
    ) {
      return true;
    } else return false;
  }

  getKmRanking() {
    this._fahrtenbuchService
      .getKilometerRangListe(this.kmRanglisteJahr)
      .subscribe(
        res => {
          this.kmRangliste = res;
          if (this.kmRangliste.length < 1) {
            this.emptyArray = true;
          } else this.emptyArray = false;
        },
        err => {
          this.kmRangliste = [];
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this._router.navigate(['/login']);
            }
          }
        }
      );
  }

  getFlussRangliste() {
    this._fahrtenbuchService
      .getFlussRangListe(this.flussRanglisteJahr)
      .subscribe(
        res => {
          this.flussRangliste = res;
          if (this.flussRangliste.length < 1) {
            this.emptyArray = true;
          } else this.emptyArray = false;
        },
        err => {
          this.flussRangliste = [];
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this._router.navigate(['/login']);
            }
          }
        }
      );
  }

  getAlleFahrtenByDate() {
    this._fahrtenbuchService
      .getFahrtenbuchByDate(
        this.alleFahrtenDatum.vonDatum,
        this.alleFahrtenDatum.bisDatum
      )
      .subscribe(
        res => (this.alleFahrtenDatum = res),
        err => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this._router.navigate(['/login']);
            }
          }
        }
      );
  }

  getFlussListe() {
    this._fahrtenbuchService.getFlussListe().subscribe(
      res => (this.riverData = res),
      err => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            this._router.navigate(["/login"]);
          }
        }
      }
    );
  }

  getAlleFahrtenList() {
    this._fahrtenbuchService.getFahrtenbuchListe().subscribe(
      res => (this.alleFahrten = res),
      err => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            this._router.navigate(["/login"]);
          }
        }
      }
    );
  }

  getMeineFahrtenByDate() {
    this._fahrtenbuchService
      .getFahrtenPaddlerByDate(
        this._auth.getUserName(),
        this.meineFahrtenDatum.vonDatum,
        this.meineFahrtenDatum.bisDatum
      )
      .subscribe(
        res => (this.paddlerFahrten = res),
        err => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this._router.navigate(["/login"]);
            }
          }
        }
      );
  }

  getMeineFahrtenList() {
    this._fahrtenbuchService
      .getFahrtenPaddlerListe(this._auth.getUserName())
      .subscribe(
        res => {
          this.paddlerFahrten = res;
        },
        err => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this._router.navigate(["/login"]);
            }
          }
        }
      );
  }

  setFahrtEintragen() {
    this.fahrtData = new Fahrt(
      "",
      "",
      "",
      0,
      0,
      "",
      "",
      this._auth.getUserName()
    );
    this.formFahrtEintragen.reset();
    this.fahrtSavedOk = false;
    this.fahrtSavedError = false;
  }

  setFlussEintragen() {
    this.flussData = new Fluss("", "", this._auth.getUserName());
    this.formFlussEintragen.reset();
    this.flussSavedOk = false;
    this.flussSavedError = false;
  }

  deleteFahrt(fahrt, index: number) {
    console.log("Index: " + index);
    console.log("Fahrt: " + fahrt.Bach + fahrt.VonDatum + " " + fahrt.BisDatum);
    this.paddlerFahrten.splice(index, 1);
    this._fahrtenbuchService.deleteFahrt(fahrt).subscribe(data => {
      if (data.ok) {
        this.fahrtDeletedOk = true;
      } else {
        this.fahrtDeletedError = true;
      }
    });
  }
}
