<footer id="sticky-footer" class="footer py-2 bg-dark text-white-50" style="z-index: 5;">
  <div class="container text-center">
      <small>
          <a type="button" class="btn bg-transparent" data-toggle="modal" data-target="#exampleModal" style="color: rgba(255,255,255,.5); text-decoration: none;">
              Impressum & Datenschutz
          </a>
      </small>
  </div>
</footer>

  <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title" id="exampleModalLabel">Impressum & Datenschutz</h3>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <h5>Für den Inhalt verantwortlich:</h5>
            <p>
                Welser Faltbootverein<br>
                Johann-Strauss-Straße 47<br>
                4600 Wels<br>
                Österreich<br>
                Email: <a href="mailto:office@kajaksport.at">office@kajaksport.at</a><br>
                ZVR 384182603<br>
            </p>
            <p>Wir sind Mitglied im Österreichischen Kanuverband (<a href="https://www.kanuverband.at/" target="_blank">OKV</a>) und Allgemeinen Sportverband Österreichs (<a href="https://www.asvoe.at" target="_blank">ASVÖ</a>).</p>
            <p>Der Welser Faltbootverein übernimmt keine Haftung für die Richtigkeit, Authentizität und Vollständigkeit der zur Verfügung gestellten Informationen, insbesondere nicht für InhalteDritter.</p>
            <hr>
            <h5>Hinweis zum Datenschutz:</h5>
            <p>Im öffentlichen Bereich dieser Website werden keinerlei personenbezogenen Daten gesammelt oder gespeichert. Bei Fragen wenden Sie sich bitte an <a href="mailto:office@kajaksport.at">datenschutz@kajaksport.at</a></p>
            <hr>
            <h5>Hinweis zum Cookies:</h5>
            <p>Es werden keine Cookies gespeichert</p>
            <hr>
            <h5>Haftungsausschluss:</h5>
            <p>Der WFV - Welser Faltboot Verein erstellt die Inhalte seiner Internetseiten mit großer Sorgfalt und überprüft bzw. aktualisiert die Inhalte regelmäßig. Dennoch kann der WFV für die Aktualität, Richtigkeit und Vollständigkeit der Inhalte keine Gewähr übernehmen. Alle Inhalte dienen nur der unverbindlichen, allgemeinen Information, die Nutzung der Inhalte der Internetseiten erfolgt auf eigene Gefahr der Besucher. Diese Seiten beinhalten auch Verweise (Links) zu externen Internetseiten Dritter, auf deren Inhalte der WFV keinen Einfluss hat und daher keine Gewähr übernimmt. Sollten Rechtsverletzungen in Verbindung mit externen Internetseiten erkennbar werden, werden die Verweise selbstverständlich umgehend entfernt.</p>
            <hr>
            <h5>Genderhinweis:</h5>
            <p>Allein aus Gründen der besseren Lesbarkeit wird auf die gleichzeitige Verwendung männlicher und weiblicher Sprachformen verzichtet. Sämtliche Personenbezeichnungen gelten für beide Geschlechter</p>
        </div>
      </div>
    </div>
  </div>
