<div class="container pt-3 fix-footer">
  <div class="card text-center">
    <div class="card-header">
      <h3 class="mb-0">Login</h3>
    </div>
    <div class="card-body">
      <div class="row pt-3">
        <div class="col-md-6 mx-auto">
          <div class="card rounded-0">
            <div class="card-body">
              <form class="form">
                <div class="form-group">
                  <label for="">Name</label>
                  <input [(ngModel)]="loginUserData.username" name="username" type="text" class="form-control rounded-0" required>
                </div>
                <div class="form-group">
                  <label for="">Passwort</label>
                  <input [(ngModel)]="loginUserData.passwort" name="passwort" type="password" class="form-control rounded-0" required>
                </div>
                <button (click)="loginUser()" type="submit" class="btn btn-success float-right">Login</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer text-muted">
    </div>
  </div>
</div>