<div class="container pt-3 fix-footer">
  <div class="card text-center">
    <div class="card-header">
      <h3 class="mb-0">Kontakt</h3>
    </div>
    <div class="card-body">
      <div class="row featurette">
        <div class="col-md-6">
          <h4 class="featurette-heading">WFV Bootshaus<span class="text-muted"></span></h4>
          <p class="lead">Direkt an der Traun neben Polizeisportverein und Freibad</p>
          <h4 class="featurette-heading">Vereinsadresse<span class="text-muted"></span></h4> (Anschrift)
          <p class="lead">Welser Faltboot Verein</p>
          <p class="lead">Johann-Strauss-Straße 47, 4600 Wels, Österreich</p>
          <p><a href= "mailto:office@kajaksport.at">office@kajaksport.at</a></p>
          <p>Das Beitrittsformular findest du
                    <a
                      href="assets\formulare\WFV-Beitrittserklaerung.pdf"
                      download
                      style="text-decoration: none;"
                      >hier</a
                    >
                    als Download.
          </p>
        </div>
        <div class="col-md-6">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2662.070577188909!2d14.012729316043563!3d48.14744455845206!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4773f28ecec0d7af%3A0xab94f6b7402bafc1!2sWFV%20Bootshaus!5e0!3m2!1sde!2sat!4v1583247646114!5m2!1sde!2sat" width="400" height="400" frameborder="0" style="border:0;" allowfullscreen=""></iframe>
        </div>
      </div>

      <hr>

      <div class="py-3">
        <div class="container">
          <div class="row justify-content-center mb-4">
            <div class="col-md-7 text-center">
              <h3 class="mb-3">Unser Team, Vorstand und Funktionäre</h3>
            </div>
          </div>
          <div class="row">
            <!-- column  -->
            <div class="col-lg-4 mb-4">
              <!-- Row -->
              <div class="row">
                <div class="col-md-12">
                  <img src="assets/img/profilbilder/Robert_Profilbild.jpg" alt="wrapkit" class="img-fluid rounded-circle" width="140" height="140"/>
                </div>
                <div class="col-md-12 text-center">
                  <div class="pt-2">
                    <h5 class="mt-4 font-weight-medium mb-0">Robert Angermayr</h5>
                    <h6 class="subtitle mb-3">Obmann</h6>
                    <h6 class="subtitle mb-3">WW Kajakinstruktor</h6>
                    <p><a href= "mailto:robert@kajaksport.at">robert@kajaksport.at</a></p>
                  </div>
                </div>
              </div>
              <!-- Row -->
            </div>
            <!-- column  -->
            <div class="col-lg-4 mb-4">
              <!-- Row -->
              <div class="row">
                <div class="col-md-12">
                  <img src="assets/img/profilbilder/Walter_Profilbild.jpg" alt="wrapkit" class="img-fluid rounded-circle" width="140" height="140" />
                </div>
                <div class="col-md-12 text-center">
                  <div class="pt-2">
                    <h5 class="mt-4 font-weight-medium mb-0">Walter Hausleitner</h5>
                    <h6 class="subtitle mb-3">Obmann Stv.</h6>
                    <h6 class="subtitle mb-3">WW Kajakinstruktor</h6>
                    <p><a href= "mailto:tom@kajaksport.at">walter@kajaksport.at</a></p>
                  </div>
                </div>
              </div>
              <!-- Row -->
            </div>
            <!-- column  -->
            <!-- column  -->
            <div class="col-lg-4 mb-4">
              <!-- Row -->
              <div class="row">
                <div class="col-md-12">
                  <img src="assets/img/profilbilder/img_avatar.png" alt="wrapkit" class="img-fluid rounded-circle" width="140" height="140" />
                </div>
                <div class="col-md-12 text-center">
                  <div class="pt-2">
                    <h5 class="mt-4 font-weight-medium mb-0">Rainer Gschwandtner</h5>
                    <h6 class="subtitle mb-3">Kassier</h6>
                    <p><a href= "mailto:rainer@kajaksport.at">rainer@kajaksport.at</a></p>
                  </div>
                </div>
              </div>
              <!-- Row -->
            </div>
            <!-- column  -->
            <!-- column  -->
            <div class="col-lg-4 mb-4">
              <!-- Row -->
              <div class="row">
                <div class="col-md-12">
                  <img src="assets/img/profilbilder/Max_Profilbild.JPG" alt="wrapkit" class="img-fluid rounded-circle" width="140" height="140" />
                </div>
                <div class="col-md-12 text-center">
                  <div class="pt-2">
                    <h5 class="mt-4 font-weight-medium mb-0">Markus (Max) Bruckner</h5>
                    <h6 class="subtitle mb-3">Bootshauswart</h6>
                    <h6 class="subtitle mb-3">WW Kajakinstruktor</h6>
                    <p><a href= "mailto:max@kajaksport.at">max@kajaksport.at</a></p>
                  </div>
                </div>
              </div>
              <!-- Row -->
            </div>
            <!-- column  -->
            <!-- column  -->
            <div class="col-lg-4 mb-4">
              <!-- Row -->
              <div class="row">
                <div class="col-md-12">
                  <img src="assets/img/profilbilder/Daniel_Profilbild.jpg" alt="wrapkit" class="img-fluid rounded-circle" width="140" height="140"/>
                </div>
                <div class="col-md-12 text-center">
                  <div class="pt-2">
                    <h5 class="mt-4 font-weight-medium mb-0">Daniel Fasthuber</h5>
                    <h6 class="subtitle mb-3">Fahrten- & Jugendwart</h6>
                    <h6 class="subtitle mb-3">WW Kajakinstruktor</h6>
                    <p><a href= "mailto:daniel@kajaksport.at">daniel@kajaksport.at</a></p>
                  </div>
                </div>
              </div>
              <!-- Row -->
            </div>
            <!-- column  -->
            <div class="col-lg-4 mb-4">
              <!-- Row -->
              <div class="row">
                <div class="col-md-12">
                  <img src="assets/img/profilbilder/img_avatar_w.png" alt="wrapkit" class="img-fluid rounded-circle" width="140" height="140" />
                </div>
                <div class="col-md-12 text-center">
                  <div class="pt-2">
                    <h5 class="mt-4 font-weight-medium mb-0">Petra Spatt</h5>
                    <h6 class="subtitle mb-3">Schriftführerin</h6>
                    <p><a href= "mailto:rainer@kajaksport.at">petra@kajaksport.at</a></p>
                  </div>
                </div>
              </div>
              <!-- Row -->
            </div>
          </div>
          <p class="subtitle">
            Beim WFV gibt es wie in jedem offiziell gemeldeten Verein einen Vorstand, bestehend zumindest aus Obmann, Schriftführer, Kassier und deren Stellvertreter. Zusätzlich übernehmen Funktionäre wichtige Aufgaben im Verein. Ein großes Danke an die vielen, hier nicht aufgelisteten, ehrenamtlichen Helfer, die unsere Ausrüstung in Schuss halten, an Arbeitstagen mit anpacken oder spontan bei anfallenden Reparaturen helfen - ohne euch würde unser Verein nicht funktionieren!
          </p>
        </div>
      </div>
    </div>
    <div class="card-footer text-muted">
    </div>
  </div>
</div>
