<div class="row pt-5">
  <div class="col-md-6 mx-auto">
    <div class="card rounded-0">
      <div class="card-header">
        <h3 class="mb-0">Anmelden</h3>
      </div>
      <div class="card-body">
        <form class="form">
          <div class="form-group">
            <label for="">Email</label>
            <input [(ngModel)]="registerUserData.email" name="email" type="text" class="form-control rounded-0" required>
          </div>
          <div class="form-group">
            <label for="">Passwort</label>
            <input [(ngModel)]="registerUserData.passwort" name="passwort" type="password" class="form-control rounded-0" required>
          </div>
          <button (click)="registerUser()" type="button" class="btn btn-primary float-right">Anmelden</button>
        </form>
      </div>
    </div>
  </div>
</div>
