import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-verein',
  templateUrl: './verein.component.html',
  styleUrls: ['./verein.component.css']
})
export class VereinComponent implements OnInit {

  allgemeines = [
    {
      frage: 'Wie kann ich das Kajakfahren erlernen?',
      antwort:'Idealerweise in einem Verein, von einem Kajaklehrwart oder bei Kajakanfängerkursen diverser Kajakschulen.'
    },
    {
      frage: 'Wann ist der beste Zeitpunkt für den Einstieg?',
      antwort:'Wir starten die Saison im Frühling, daher wäre es von großem Vorteil schon den Winter über am Kenterrollentraining im Hallenbad regelmäßig teilzunehmen. Hier bekommst du die ersten Basics vermittelt und kannst schon an der Rolle feilen, um für die ersten Fahrten am Fluss gewappnet zu sein. Es gibt aber auch ein Anfängertraining und Schnuppertage an denen du einsteigen kannst.'
    },
    {
      frage: 'Welche Voraussetzungen muss ich für den Kajak-/Kanusport mitbringen?',
      antwort:'Du solltest körperlich fit sein und gute Schwimmkenntnisse mitbringen. Kaltes Wasser und Kopf unter Wasser dürfen keine Angst auslösen.'
    },
    {
      frage: 'Mit welchem Alter kann ich Kajaksport betreiben?',
      antwort:'Idealerweise beginnt man schon in jungen Jahren, spielerisch und im stehenden Gewässer. Wie in anderen Sportarten auch, gilt: "früh übt sich, wer ein Meister werden will". Dennoch spielen körperliche und geistige Reife, sowie gute Schwimmkenntnisse eine wesentliche Rolle im Kajaksport. Daher ist ein alter ab 12 Jahren zu empfehlen.'
    },
    {
      frage: 'Ich möchte Kajak- Kanufahren als Rennsport betreiben. Bin ich bei euch richtig?',
      antwort:'Jein. Wir sehen uns als Verein im Breitensport angesiedelt. Auch wenn es in der Vergangenheit eine Sektion Rennsport gab, haben wir diese aufgelassen. Wir stellen daher keine Trainer und nehmen an keinen Wettkämpfen im Leistungssport teil. Wir freuen uns aber trotzdem Leistungssportler aufzunehmen und vom gemeinsamen Austausch zu profitieren.'
    }
  ];

  mitgliedschaft = [
    {
      frage: 'Wie werde ich Mitglied?',
      antwort:'Wenn du nach den ersten Versuchen im Hallenbad, Flachwasser und leichtem Wildwasser Mitglied werden möchtest, dann besteht die Möglichkeit einen Antrag auf Aufnahme zu stellen. Bitte fülle die Beitrittserklärung (siehe Downloads) aus und komme zu einer der monatlichen Vorstandssitzungen (immer erster Dienstag im Monat, 19 Uhr im Bootshaus)'
    },
    {
      frage: 'Ich bin nicht aus Wels, ist das ein Problem?',
      antwort:'Nein das ist kein Problem. Wir freuen uns über Mitglieder aus ganz Oberösterreich und über die Bundesgrenzen hinaus. Wichtig ist uns nur, dass du dich blicken lässt und auch am Vereinsleben aktiv teilnimmst.'
    }
  ];

  equipment = [
    {
      frage: 'Kann ich ein Boot/Ausrüstung ausleihen?',
      antwort:'Wenn du kein Vereinsmitglied bzw. nicht interessiert bist dem Verein beizutreten, ist es leider nicht möglich Vereinsmaterial auszuleihen. Der WFV stellt seinen Mitgliedern derzeit Leihausrüstung kostenlos zur Verfügung. Diese Ausrüstung ist aber ausschließlich für die ersten Paddelversuche gedacht. Ein sorgsamer Umgang mit dem vereinseigenen Material ist unbedingt erforderlich. Alle Schäden oder fehlenden Teile sind umgehend dem Vereinsvorstand zu melden. Längerfristige Ausleihen müssen dem Fahrtenwart bekannt gegeben werden.'
    },
    {
      frage: 'Ich möchte ein Boot im Bootshaus einstellen. Ist das möglich?',
      antwort:'Wenn ein Platz frei ist, ist es für Vereinsmitglieder möglich einen Bootsplatz zu mieten (€25.-/Jahr). Der Bootshauswart hilft dir gerne bei der Stellpaltzsuche. Da Platzmangel unter den Stellplätzen herrscht, wird darauf geachtet, dass das reine Einlagern von ungenutzen Booten vermieden wird, um Platz für aktive Vereinsmitglieder zu schaffen.'
    },
    {
      frage: 'Wie werden die Boote transportiert?',
      antwort:'Da gibt es verschiedene Wege. Dachträger, Bootsanhänger oder im Kofferraum (wenn dieser groß genug ist), wichtig ist die sachgerechte Sicherung mittels Spanngurte. Hier bitte nicht die billigste Variante aus dem Baumarkt kaufen und auf keinen Fall Expander verwenden. z.B.: <a href="https://www.kajak-kletter-outdoor-laden.com/Kanu-Kajak/Transport/Kajak-Kanu-ZURRGURT-3-5-m::321.html" target="_blank">Kajak Kanu Zurrgurt</a>'
      }
  ];

  training = [
    {
      frage: 'Kann ich auch als Nicht-Mitglied beim Kenterrollentraining mitmachen?',
      antwort:'Ja, du kannst gerne nach Voranmeldung beim jeweiligen Kenterrollentermin vorbeikommen. Für Nicht-Mitglieder kostest die Teilnahme €5.- pro Person und wird vor Ort eingesammelt.'
    },
    {
      frage: 'Wann und wo werden die Termine für das Kenterrollentraining bekanntgegeben?',
      antwort:'Die Termine werden über die Website und im Fahrtenprogramm bekanntgegeben. Wir starten meist Anfang November im Schulhallenbad der Volksschule Gunskirchen, Lambacher Str. 4, 4623 Gunskirchen.'
    },
    {
      frage: 'Warum ist die Kenterrolle wichtig?',
      antwort:'Kurz gesagt, damit du im Idealfall nicht mehr schwimmen musst, wenn du kenterst. Es gibt ein gutes Buch <a href="https://www.amazon.de/Eskimorolle-leicht-gemacht-Kanadierrolle-Anf%C3%A4nger/dp/3899610318" target="_blank">Eskimorolle leicht gemacht</a>, welches auch bei uns im Bootshaus aufliegt und unzählige Youtube Videos zum Thema.'
    },
    {
      frage: 'Welche Ausrüstung brauche ich für das Kenterrollentraining?',
      antwort:'Badesachen sind ein muss. Ansonsten kannst du eine Schwimmbrille und Nasenklammer mitbringen.'
    },
    {
      frage: 'Ich will nicht auf`s Wildwasser. Sollte ich trotzdem die Kenterrolle erlernen?',
      antwort:'Das musst du für dich entscheiden. Zwingend notwendig ist es nicht, aber es gibt dir auf jeden Fall mehr Sicherheit, egal ob Flach- oder Wildwasser.'
    }
  ];

  schnuppern = [
    {
      frage: 'Ich würde gerne einmal bei einer Ausfahrt mitpaddeln. Ist das möglich und wen soll ich kontaktieren?',
      antwort:'Ja gegen Voranmeldung bei unserem Fahrtenwart ist es möglich an einem Schnuppertermin auf der Traun mitzupaddeln.'
    },
    {
      frage: 'Welche Ausrüstung brauche ich zum Schnupperpaddeln?',
      antwort:'Eigentlich nur Badesachen, alles andere haben wir als Vereinsmaterial für dich. Wenn du einen eigenen Neoprenanzug hast, 3-5mm ohne Ärmel (Long John) und/oder Paddelschuhe kannst du diese gerne mitnehmen.'
    },
    {
      frage: 'Wie komme ich nach dem Paddeln wieder zum Einstieg (Fahrzeug) zurück?',
      antwort:'Da wir meistens flussabwärts paddeln, muss man entweder zurück fahren (mit einem zweiten Auto oder einem Fahrrad das beim Ausstieg steht), Autostoppen, zurück laufen oder man hat ein <a href="https://www.urbandictionary.com/define.php?term=shuttle%20bunny" target="_blank">Shuttle Bunny</a> dabei :D.'
    }
  ];

  safety = [
    {
      frage: 'Kann ich auch alleine paddeln?',
      antwort:'Wir im Verein sehen das Kajakfahren als Teamsport. Man passt gegenseitig aufeinander auf und es wird von unserer Seite nicht empfohlen alleine paddeln zu gehen. In leichtem Gewässer und mit fortgeschrittenem Können (Kenterrolle!), könnte man auch alleine paddeln.'
    },
    {
      frage: 'Wie riskant ist der Kajaksport?',
      antwort:'Der Wildwasser Kajaksport ist vergleichbar mit anderen Extremsportarten, wie Skitouren gehen, Downhill fahren, Paragleiten, etc.'
    },
    {
      frage: 'Welche Sicherheitsausrüstung brauche ich am Wildwasser?',
      antwort:'Du solltest nie ohne Helm, Schwimmweste und festem Schuhwerk unterwegs sein. Ziehe dich immer den Wassertemperaturen entsprechend an. Außerdem solltest du einen Wurfsack, Bandschlinge, Karabiner, Messer, Erste Hilfe Set, Handy immer dabei haben. Es gibt etliche Kurse und Literatur zum Thema.'
    }
  ];

  constructor() { }

  ngOnInit() {

  }

}
