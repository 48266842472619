import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EventService {
  private _eventsUrl = "https://test.kajaksport.at:8091/wfv/getEvents"

  constructor(private _http: HttpClient) { }

  getEvents(){
    return this._http.get<any>(this._eventsUrl)
  }
}
