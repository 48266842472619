import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AuthService } from "./auth.service";
import { Fahrt } from "./fahrtenbuch/fahrt.model";
import { Fluss } from "./fahrtenbuch/fluss.model";
import { Subscription } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class FahrtenbuchService {
  private _serverUrl = "https://test.kajaksport.at:8091/";
  private _fahrtenbuchUrl = this._serverUrl + "wfv/getFahrtenbuch/";
  private _flussUrl = this._serverUrl + "wfv/getFluss/";
  private _paddlerFahrtenbuchUrl = this._serverUrl + "wfv/getFahrtenbuch/";
  private _rankingFahrtenbuchUrl =
    this._serverUrl + "wfv/getKilometerrangliste/";
  private _flussFahrtenbuchUrl = this._serverUrl + "wfv/getFlussrangliste/";
  private _fahrtEintragen = this._serverUrl + "wfv/postFahrtenbuch";
  private _flussEintragen = this._serverUrl + "wfv/postFluss";
  private _fahrtLoeschen = this._serverUrl + "wfv/deleteFahrt";
  postId;
  constructor(private _http: HttpClient, private _auth: AuthService) {}

  getFahrtenbuchListe() {
    console.log("Request to: " + this._fahrtenbuchUrl);
    return this._http.get<any>(this._fahrtenbuchUrl);
  }

  getFlussListe() {
    console.log("Request to: " + this._flussUrl);
    return this._http.get<any>(this._flussUrl);
  }

  getFahrtenbuchByDate(fromDate: string, toDate: string) {
    let fahrtenbuchUrl = this._fahrtenbuchUrl + fromDate + "/" + toDate;
    console.log("Request to: " + fahrtenbuchUrl);
    return this._http.get<any>(fahrtenbuchUrl);
  }

  getFahrtenPaddlerListe(paddlerId: string) {
    let paddlerFahrtenbuchUrl = this._paddlerFahrtenbuchUrl + paddlerId;
    console.log("Request to: " + paddlerId + paddlerFahrtenbuchUrl);
    return this._http.get<any>(paddlerFahrtenbuchUrl);
  }

  getFahrtenPaddlerByDate(paddlerId: string, fromDate: string, toDate: string) {
    let paddlerFahrtenbuchUrl =
      this._paddlerFahrtenbuchUrl + paddlerId + "/" + fromDate + "/" + toDate;
    console.log("Request to: " + paddlerFahrtenbuchUrl);
    return this._http.get<any>(paddlerFahrtenbuchUrl);
  }

  getKilometerRangListe(year: number) {
    let fromDate = year + "-01-01";
    let toDate = year + "-12-31";
    let rankingFahrtenbuchUrl =
      this._rankingFahrtenbuchUrl + fromDate + "/" + toDate;
    console.log("Request to: " + rankingFahrtenbuchUrl);
    return this._http.get<any>(rankingFahrtenbuchUrl);
  }

  getFlussRangListe(year: number) {
    let fromDate = year + "-01-01";
    let toDate = year + "-12-31";
    let flussFahrtenbuchUrl =
      this._flussFahrtenbuchUrl + fromDate + "/" + toDate;
    console.log("Request to: " + flussFahrtenbuchUrl);
    return this._http.get<any>(flussFahrtenbuchUrl);
  }

  postFahrt(fahrt: Fahrt) {
    console.log("Request to: " + this._fahrtEintragen);
    return this._http.post<any>(this._fahrtEintragen, fahrt);
  }

  postFluss(fluss: Fluss) {
    console.log("Request to: " + this._flussEintragen);
    return this._http.post<any>(this._flussEintragen, fluss);
  }

  deleteFahrt(fahrt: Fahrt) {
    console.log("Request to: " + this._fahrtLoeschen);
    return this._http.post<any>(this._fahrtLoeschen, fahrt);
  }
}
