import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { CarouselModule } from "ngx-owl-carousel-o";
import { FormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { LoginComponent } from "./login/login.component";
import { KontaktComponent } from "./kontakt/kontakt.component";
import { AktuellComponent } from "./aktuell/aktuell.component";
import { VereinComponent } from "./verein/verein.component";
import { RegisterComponent } from "./register/register.component";
import { AuthService } from "./auth.service";
import { MembersComponent } from "./members/members.component";
import { HomeComponent } from "./home/home.component";
import { EventService } from "./event.service";
import { FahrtenbuchService } from "./fahrtenbuch.service";
import { FahrtenbuchComponent } from "./fahrtenbuch/fahrtenbuch.component";
import { BerichteComponent } from "./berichte/berichte.component";
import { EventsComponent } from "./events/events.component";
import { CountryFilterPipe } from "./country-filter.pipe";
import { AuthGuard } from "./auth.guard";
import { TokenInterceptorService } from "./token-interceptor.service";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SponsorCarouselComponent } from "./sponsor-carousel/sponsor-carousel.component";
import { SafePipe } from "./safe.pipe";
import { FooterComponent } from './footer/footer.component';
import { PegelComponent } from './pegel/pegel.component';
import { NgxSpinnerModule } from "ngx-spinner";
import * as $ from "jquery";

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    LoginComponent,
    KontaktComponent,
    AktuellComponent,
    VereinComponent,
    RegisterComponent,
    MembersComponent,
    HomeComponent,
    FahrtenbuchComponent,
    BerichteComponent,
    EventsComponent,
    CountryFilterPipe,
    SponsorCarouselComponent,
    SafePipe,
    FooterComponent,
    PegelComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    CarouselModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxSpinnerModule
  ],
  providers: [
    AuthService,
    EventService,
    FahrtenbuchService,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
