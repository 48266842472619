export class Fahrt {
  constructor(
    public fluss: string,
    public vonDatum: string,
    public bisDatum: string,
    public kilometer: number,
    public pegel: number,
    public level: string,
    public kommentar: string,
    public person: string
  ) {}
}
