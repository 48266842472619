<!-- https://o7planning.org/de/11971/anleitung-bootstrap-card -->
<div class="container pt-3 fix-footer">
  <div class="card text-center ">
    <div class="card-header">
      <h3 class="mb-0">Aktuell</h3>
    </div>
    <div class="card-body">
      <!-- Nav tabs -->
      <ul class="nav nav-tabs justify-content-center">
        <li class="nav-item">
          <a class="nav-link active" data-toggle="tab" href="#info"
            ><i class="fa fa-calendar"></i> Info
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="tab" href="#berichte"
            ><i class="fa fa-pencil-square-o"></i> Berichte</a
          >
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="tab" href="#videos"
            ><i class="fa fa-youtube-play"></i> Videos</a
          >
        </li>
      </ul>

      <!-- Tab panes -->
      <div class="tab-content pt-3">
        <div class="tab-pane container active pt-3" id="info">
          <div class="row pt-3">
            <div class="col-lg-12">
              <h5>
                Das aktuelle Fahrtenprogramm findest du
                <a
                  href="assets\formulare\WFV-Fahrtenprogramm.pdf"
                  download
                  style="text-decoration: none;"
                  >hier</a
                >
                als Download.
              </h5>
              <h6 class="pt-1">
                Viele unserer Ausfahrten werden spantan und von den Mitgliedern selbst organisiert. Dazu gibt es eine eigene WhatsApp Gruppe.
                <br>Am besten informierst du unseren Fahrtenwart oder jemanden vom Vorstand, wenn du dieser Gruppe beitreten möchtest.
              </h6>
            </div>
          </div>

<!--          <div class="table-responsive-sm pt-3">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th scope="col">Datum</th>
                  <th scope="col">Event</th>
                  <th scope="col">Kontakt/Anmeldung</th>
                </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let event of events; let i = index" onclick="input" data-toggle="modal" attr.data-target="{{ event.target }}" style="cursor: pointer">
                    <td *ngIf="event.bisDatum">{{ event.vonDatum }} - {{ event.bisDatum }}</td>
                    <td *ngIf="!event.bisDatum">{{ event.vonDatum }}</td>
                    <td>{{ event.titel }}</td>
                    <td>{{ event.kontakt }}</td>
                  </tr>
              </tbody>
            </table>

            <div *ngFor="let event of events; let i = index"
            class="modal fade"
            id="{{ event.id }}"
            tabindex="-1"
            role="dialog"
            aria-labelledby="myModalLabel"
            aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div class="modal-content">
                  &lt;!&ndash; Modal Header &ndash;&gt;
                  <div class="modal-header">
                    <h4 class="modal-title">
                      {{ event.titel }} {{ event.vonDatum }}
                    </h4>
                    <button type="button" class="close" data-dismiss="modal">
                      &times;
                    </button>
                  </div>

                  &lt;!&ndash; Modal body &ndash;&gt;
                  <div class="modal-body">
                    <div class="row featurette">
                      <div class="col-md-7">
                        <p class="lead">
                          {{ event.text1 }}
                        </p>
                      </div>
                      <div class="col-md-5">
                        <img
                          src="{{ event.img1 }}"
                          alt="..."
                          style="width:100%"
                        />
                      </div>
                    </div>

                    <hr class="featurette-divider" />

                    <div class="row featurette">
                      <div class="col-md-7 order-md-2">
                        <p class="lead">
                          {{ event.text2 }}
                        </p>
                      </div>
                      <div class="col-md-5 order-md-1">
                        <img
                          src="{{ event.img2 }}"
                          alt="..."
                          style="width:100%"
                        />
                      </div>
                    </div>
                  </div>

                  &lt;!&ndash; Modal footer &ndash;&gt;
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Schließen
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ngx-spinner></ngx-spinner>-->

          <div class="row pt-3">
            <div class="col-lg-6">
              <p>Offizieller FB-Feed des WFV</p>
              <div class="fb-page" data-href="https://www.facebook.com/WFV-Welser-Faltbootverein-116859331726727/" data-tabs="timeline,events" data-width="500" data-height="580" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="false">
                <blockquote cite="https://www.facebook.com/WFV-Welser-Faltbootverein-116859331726727/" class="fb-xfbml-parse-ignore">
                  <a href="https://www.facebook.com/WFV-Welser-Faltbootverein-116859331726727/">WFV (Welser Faltbootverein)</a>
                </blockquote>
              </div>
            </div>
            <div class="col-lg-6">
              <p>FB-Feed unserer Bullfrogs</p>
              <div class="fb-page" data-href="https://www.facebook.com/BullfrogBoofTeam/" data-tabs="timeline,events" data-width="500" data-height="580" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="false">
                <blockquote cite="https://www.facebook.com/BullfrogBoofTeam/" class="fb-xfbml-parse-ignore">
                  <a href="https://www.facebook.com/BullfrogBoofTeam/">Bullfrog Boof Team</a>
                </blockquote>
              </div>
            </div>
          </div>

        </div>

        <div class="tab-pane container fade" id="berichte">
          <div class="row pt-3">
            <div
              *ngFor="let bericht of berichte; let i = index"
              class="col-lg-4 col-md-12 mb-4"
            >
              <div
                class="modal fade"
                id="{{ bericht.id }}"
                tabindex="-1"
                role="dialog"
                aria-labelledby="myModalLabel"
                aria-hidden="true"
              >
                <div
                  class="modal-dialog modal-dialog-centered modal-lg"
                  role="document"
                >
                  <div class="modal-content">
                    <!-- Modal Header -->
                    <div class="modal-header">
                      <h4 class="modal-title">
                        {{ bericht.titel }} {{ bericht.date }}
                      </h4>
                      <button type="button" class="close" data-dismiss="modal">
                        &times;
                      </button>
                    </div>

                    <!-- Modal body -->
                    <div class="modal-body">
                      <div class="row featurette">
                        <div class="col-md-7">
                          <p class="lead">
                            {{ bericht.text1 }}
                          </p>
                        </div>
                        <div class="col-md-5">
                          <img
                            src="{{ bericht.img1 }}"
                            alt="..."
                            style="width:100%"
                          />
                        </div>
                      </div>

                      <hr class="featurette-divider" />

                      <div class="row featurette">
                        <div class="col-md-7 order-md-2">
                          <p class="lead">
                            {{ bericht.text2 }}
                          </p>
                        </div>
                        <div class="col-md-5 order-md-1">
                          <img
                            src="{{ bericht.img2 }}"
                            alt="..."
                            style="width:100%"
                          />
                        </div>
                      </div>
                    </div>

                    <!-- Modal footer -->
                    <div class="modal-footer">
                      <button
                        type="button"
                        class="btn btn-secondary"
                        data-dismiss="modal"
                      >
                        Schließen
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <a
                type="button"
                data-toggle="modal"
                attr.data-target="{{ bericht.target }}"
              >
                <div class="card mb-4">
                  <img
                    src="{{ bericht.img }}"
                    class="card-img-top"
                    alt="..."
                    height="200"
                    width="100%"
                  />
                  <div class="card-body">
                    <h5 class="card-title">{{ bericht.titel }}</h5>
                    <p class="card-text">
                      {{ bericht.date }}
                    </p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>

        <div class="tab-pane container fade" id="videos">
          <div class="row">
            <div
              *ngFor="let video of videos; let i = index"
              class="col-lg-4 col-md-12 mb-4"
            >
              <div
                class="modal fade"
                id="{{ video.id }}"
                tabindex="-1"
                role="dialog"
                aria-labelledby="myModalLabel"
                aria-hidden="true"
              >
                <div
                  class="modal-dialog modal-dialog-centered modal-lg"
                  role="document"
                >
                  <div class="modal-content">
                    <div class="modal-body mb-0 p-0">
                      <div
                        class="embed-responsive embed-responsive-16by9 z-depth-1-half"
                      >
                        <iframe
                          class="{{ video.class }} embed-responsive-item"
                          [src]="video.src | safe"
                          allowfullscreen
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {{ video.titel }}
              <a
                type="button"
                data-toggle="modal"
                attr.data-target="{{ video.target }}"
              >
                <img
                  class="img-fluid z-depth-1"
                  src="{{ video.img }}"
                  alt="video"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer text-muted"></div>
  </div>
</div>
