<div class="container pt-3">
  <!-- Nav tabs -->
  <ul class="nav nav-tabs justify-content-center">
    <li class="nav-item">
      <a
        (click)="getAlleFahrtenList()"
        class="nav-link"
        data-toggle="tab"
        href="#alle"
        >Alle Fahrten</a
      >
    </li>
    <li class="nav-item">
      <a
        (click)="getMeineFahrtenList()"
        class="nav-link"
        data-toggle="tab"
        href="#meine"
        >Meine Fahrten</a
      >
    </li>
    <li class="nav-item">
      <a
        (click)="setFahrtEintragen()"
        class="nav-link active"
        data-toggle="tab"
        href="#fahrtEintragen"
        >Fahrt eintragen</a
      >
    </li>
    <li class="nav-item">
      <a
        (click)="setFlussEintragen()"
        class="nav-link"
        data-toggle="tab"
        href="#flussEintragen"
        >Fluss eintragen</a
      >
    </li>
    <li class="nav-item">
      <a
        (click)="getKmRanking()"
        class="nav-link"
        data-toggle="tab"
        href="#kilometerrangliste"
        >Kilometerrangliste<!--{{_year}}--></a
      >
    </li>
    <li class="nav-item">
      <a
        (click)="getFlussRangliste()"
        class="nav-link"
        data-toggle="tab"
        href="#flusshitparade"
        >Flusshitparade</a
      >
    </li>
  </ul>

  <!-- Tab panes -->
  <div class="tab-content pt-3">
    <div class="tab-pane container active" id="fahrtEintragen">
      <div class="row">
        <div class="col-md-6 mx-auto">
          <div *ngIf="fahrtSavedOk" class="alert alert-success" role="alert">
            Fahrt wurde erfolgreich gespeichert!
          </div>
          <div *ngIf="fahrtSavedError" class="alert alert-danger" role="alert">
            Fahrt konnte nicht gespeichert werden! Bitte überprüfe, ob die Fahrt
            bereits eingetragen wurde..
          </div>
          <div class="card rounded-0">
            <div class="card-header">
              <h3 class="mb-0">Fahrt eintragen</h3>
            </div>
            <div class="card-body">
              <form
                class="form"
                (ngSubmit)="postFahrtSpeichern(fahrtEintragen)"
                #fahrtEintragen="ngForm"
              >
                <div class="form-group">
                  <label for="fluss">Fluss:</label>

                  <select
                    [(ngModel)]="fahrtData.fluss"
                    class="form-control rounded-0"
                    id="fluss"
                    data-live-search="true"
                    name="fluss"
                    type="text"
                    title="Fluss auswählen..."
                    placeholder="Fluss"
                    required
                    #fluss="ngModel"
                  >
                    <option></option>
                    <option *ngFor="let river of riverData">
                      {{ river.Name }}
                    </option>
                  </select>
                  <span
                    *ngIf="!fluss.valid && fluss.touched"
                    class="help-block"
                  >
                    Bitte Fluss auswählen
                  </span>
                </div>

                <div class="form-group">
                  <div class="row">
                    <div class="col-sm">
                      <label for="">Von: </label>
                      <input
                        [(ngModel)]="fahrtData.vonDatum"
                        name="vonDatum"
                        type="date"
                        class="form-control rounded-0"
                        required
                        #vonDatum="ngModel"
                      />
                      <span
                        class="datumAlert help-block"
                        *ngIf="validateDatum() && bisDatum.dirty"
                      >
                        Datum größer als Bis Datum
                      </span>
                    </div>
                    <div class="col-sm">
                      <label for="">Bis: </label>
                      <input
                        [(ngModel)]="fahrtData.bisDatum"
                        name="bisDatum"
                        type="date"
                        class="form-control rounded-0"
                        [disabled]="!vonDatum.valid && !vonDatum.touched"
                        #bisDatum="ngModel"
                      />
                      <span
                        class="datumAlert help-block"
                        *ngIf="validateDatum() && bisDatum.dirty"
                      >
                        Datum kleiner als Von Datum
                      </span>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <div class="row">
                    <div class="col-sm">
                      <label for="">Kilometer: </label>
                      <input
                        [(ngModel)]="fahrtData.kilometer"
                        name="kilometer"
                        type="number"
                        class="form-control rounded-0"
                        required
                        #km="ngModel"
                      />
                      <span *ngIf="!km.valid && km.touched" class="help-block">
                        Bitte Kilometer eintragen
                      </span>
                    </div>
                    <div class="col-sm">
                      <label for="">Pegel: </label>
                      <input
                        [(ngModel)]="fahrtData.pegel"
                        name="pegel"
                        type="number"
                        class="form-control rounded-0"
                      />
                    </div>
                    <div class="col-sm">
                      <label></label>
                      <div class="radio" *ngFor="let level of levels">
                        <input
                          type="radio"
                          name="level"
                          ngModel
                          [value]="level"
                        />
                        {{ level }}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label for="">Kommentar: </label>
                  <textarea
                    [(ngModel)]="fahrtData.kommentar"
                    class="form-control"
                    rows="5"
                    name="kommentar"
                  ></textarea>
                </div>

                <button
                  type="submit"
                  class="btn btn-success float-right"
                  [disabled]="!fahrtEintragen.valid || validateDatum()"
                >
                  Speichern
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="tab-pane container fade" id="meine">
      <form class="form-inline" ngNativeValidate>
        <div class="form-group">
          <div class="row">
            <div class="col-sm-5">
              Von:
              <input
                [(ngModel)]="meineFahrtenDatum.vonDatum"
                name="vonDatum"
                type="date"
                class="form-control rounded-0"
                required
              />
            </div>

            <div class="col-sm-5">
              Bis:
              <input
                [(ngModel)]="meineFahrtenDatum.bisDatum"
                name="bisDatum"
                type="date"
                class="form-control rounded-0"
                required
              />
            </div>

            <div class="col-sm-2">
              <button
                (click)="getMeineFahrtenByDate()"
                type="submit"
                class="btn btn-success float-right"
              >
                Suchen
              </button>
            </div>
          </div>
        </div>
      </form>

      <div class="table pt-3">
        <table class="table-responsive-sm table-hover w-auto">
          <thead>
            <tr>
              <th scope="col-xs-1 ">Datum</th>
              <th scope="col-xs-1">Fluss</th>
              <th scope="col-xs-1">Kilometer</th>
              <th scope="col-xs-1">Pegel</th>
              <th scope="col-xs-1">Level</th>
              <th scope="col-xs-1">Kommentar</th>
              <th scope="col-xs-1">Bearbeiten</th>
              <th scope="col-xs-1">Löschen</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let fahrt of paddlerFahrten; let i = index">
              <th scope="row">
                {{ fahrt.VonDatum | date: "dd.MM.yyyy" }} -
                {{ fahrt.BisDatum | date: "dd.MM.yyyy" }}
              </th>
              <td>{{ fahrt.Bach }}</td>
              <td>{{ fahrt.Kilometer }}</td>
              <td>{{ fahrt.Pegel }}</td>
              <td>{{ fahrt.Level }}</td>
              <td>{{ fahrt.Kommentar }}</td>

              <td>
                <button type="button" class="btn btn-success">
                  <i class="fa fa-cogs"></i>
                </button>
              </td>
              <td>
                <!-- Button trigger modal -->
                <button
                  type="button"
                  class="btn btn-danger"
                  data-toggle="modal"
                  [attr.data-target]="'#exampleModal' + i"
                >
                  <i class="fa fa-close"></i>
                </button>
              </td>
              <!-- Modal -->
              <div
                class="modal fade"
                [attr.id]="'exampleModal' + i"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-dialog-centered" role="document">
                  <div class="modal-content">
                    <div class="modal-body text-center">
                      Soll die Fahrt wirklich gelöscht werden?
                    </div>
                    <div class="modal-footer">
                      <button
                        type="button"
                        class="btn btn-success"
                        data-dismiss="modal"
                      >
                        Nein
                      </button>
                      <button
                        (click)="deleteFahrt(fahrt, i)"
                        type="button"
                        class="btn btn-danger"
                        data-dismiss="modal"
                      >
                        Ja
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="tab-pane container fade" id="alle">
      <form class="form-inline" ngNativeValidate>
        <div class="form-group">
          <div class="row">
            <div class="col-sm-5">
              Von:
              <input
                [(ngModel)]="alleFahrtenDatum.vonDatum"
                name="vonDatum"
                type="date"
                class="form-control rounded-0"
                required
              />
            </div>

            <div class="col-sm-5">
              Bis:
              <input
                [(ngModel)]="alleFahrtenDatum.bisDatum"
                name="bisDatum"
                type="date"
                class="form-control rounded-0"
                required
              />
            </div>

            <div class="col-sm-2">
              <button type="submit" class="btn btn-success float-right">
                Suchen
              </button>
            </div>
          </div>
        </div>
      </form>

      <div class="table-responsive-sm pt-3">
        <table class="table table-hover">
          <thead>
            <tr>
              <th scope="col">Datum</th>
              <th scope="col">Paddler</th>
              <th scope="col-sm">Fluss</th>
              <th scope="col-sm">Kilometer</th>
              <th scope="col-sm">Pegel</th>
              <th scope="col">Kommentar</th>
              <!--<th scope="col-sm">Bearbeiten</th>
              <th scope="col-sm">Löschen</th>-->
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let fahrt of alleFahrten">
              <th scope="row">
                {{ fahrt.VonDatum | date: "dd.MM.yyyy" }} -
                {{ fahrt.BisDatum | date: "dd.MM.yyyy" }}
              </th>
              <td>{{ fahrt.Paddler }}</td>
              <td>{{ fahrt.Bach }}</td>
              <td>{{ fahrt.Kilometer }}</td>
              <td>{{ fahrt.Pegel }}</td>
              <td>{{ fahrt.Kommentar }}</td>

              <!--<td><button type="button" class="btn btn-success"><i class="fa fa-cogs"></i></button></td>
              <td><button type="button" class="btn btn-danger"><i class="fa fa-close"></i></button></td>-->
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="tab-pane container fade" id="flussEintragen">
      <div class="row">
        <div class="col-md-6 mx-auto">
          <div *ngIf="flussSavedOk" class="alert alert-success" role="alert">
            Fluss wurde erfolgreich gespeichert!
          </div>
          <div *ngIf="flussSavedError" class="alert alert-danger" role="alert">
            Fluss konnte nicht gespeichert werden! Bitte überprüfe, ob der Fluss
            bereits vorhanden ist..
          </div>
          <div class="card rounded-0">
            <div class="card-header">
              <h3 class="mb-0">Fluss eintragen</h3>
            </div>
            <div class="card-body">
              <form
                (ngSubmit)="postFlussSpeichern(flussEintragen)"
                #flussEintragen="ngForm"
              >
                <div class="form-row">
                  <div class="col-md-12 mb-3">
                    <label for="land">Land:</label>
                    <select
                      [(ngModel)]="flussData.land"
                      class="form-control rounded-0"
                      id="land"
                      data-live-search="true"
                      name="land"
                      type="text"
                      title="Land auswählen..."
                      placeholder="Land"
                      required
                      #land="ngModel"
                    >
                      <option *ngFor="let country of countryData">
                        {{ country.name }}
                      </option>
                    </select>
                    <span
                      *ngIf="!land.valid && land.touched"
                      class="help-block"
                    >
                      Bitte Land auswählen
                    </span>
                  </div>
                </div>

                <div class="form-row">
                  <div class="col-md-12 mb-3">
                    <label for="fluss">Fluss:</label>
                    <input
                      [(ngModel)]="flussData.name"
                      name="flussname"
                      type="flussname"
                      class="form-control rounded-0"
                      id="fluss"
                      placeholder="Fluss-Name eintragen..."
                      required
                      #flussname="ngModel"
                    />
                    <span
                      *ngIf="!flussname.valid && flussname.touched"
                      class="help-block"
                    >
                      Flussname fehlt
                    </span>
                  </div>
                </div>
                <button
                  type="submit"
                  class="btn btn-success float-right"
                  [disabled]="!flussEintragen.valid"
                >
                  Speichern
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="tab-pane container fade" id="kilometerrangliste">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <label class="input-group-text" for="inputGroupSelect01">Jahr</label>
        </div>
        <select
          (change)="onYearChangeKm($event.target.value)"
          class="custom-select"
          id="inputGroupSelect01"
        >
          <option *ngFor="let year of years" [value]="year.year">{{
            year.year
          }}</option>
        </select>
      </div>

      <div *ngIf="emptyArray" class="alert alert-danger pt-3" role="alert">
        Keine Einträge vorhanden!
      </div>

      <div *ngIf="!emptyArray" class="table-responsive-sm">
        <table class="table table-hover">
          <thead>
            <tr>
              <th scope="col">Paddler</th>
              <th scope="col">Kilometer</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let rang of kmRangliste">
              <td>{{ rang.Paddler }}</td>
              <td>{{ rang.SumKm }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="tab-pane container fade" id="flusshitparade">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <label class="input-group-text" for="inputGroupSelect01">Jahr</label>
        </div>
        <select
          (change)="onYearChangeHit($event.target.value)"
          class="custom-select"
          id="inputGroupSelect01"
        >
          <option *ngFor="let year of years" [value]="year.year">{{
            year.year
          }}</option>
        </select>
      </div>

      <div *ngIf="emptyArray" class="alert alert-danger pt-3" role="alert">
        Keine Einträge vorhanden!
      </div>

      <div *ngIf="!emptyArray" class="table-responsive-sm">
        <table class="table table-hover">
          <thead>
            <tr>
              <th scope="col">Fluss</th>
              <th scope="col">Land</th>
              <th scope="col">Kilometer Gesamt</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let fluss of flussRangliste">
              <td>{{ fluss.Bach }}</td>
              <td>{{ fluss.Staat }}</td>
              <td>{{ fluss.SumKm }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
