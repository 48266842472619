import { Component, OnInit } from '@angular/core';
import { EventService } from '../event.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { events } from '../../assets/events.json';
import { IEvent } from './event.model';

declare global {
  interface Window {
      FB: any;
  }
}
@Component({
  selector: 'app-aktuell',
  templateUrl: './aktuell.component.html',
  styleUrls: ['./aktuell.component.css']
})
export class AktuellComponent implements OnInit {
  events: IEvent[];
  videos = [
    {
      id: 'modal1',
      target: '#modal1',
      frame: 'frame1',
      src: 'https://www.youtube.com/embed/ZkfQnEj13dA?autoplay=1',
      img: 'https://img.youtube.com/vi/ZkfQnEj13dA/0.jpg',
      class: 'modal1class',
      titel: 'Portugal/Galicia'
    },
    {
      id: 'modal2',
      target: '#modal2',
      frame: 'frame2',
      src: 'https://www.youtube.com/embed/Fa5_I8p8VeQ?autoplay=1',
      img: 'https://img.youtube.com/vi/Fa5_I8p8VeQ/0.jpg',
      class: 'modal2class',
      titel: 'Ötztal'
    },
    {
      id: 'modal3',
      target: '#modal3',
      frame: 'frame3',
      src: 'https://www.youtube.com/embed/4mpgKuwzVw0?autoplay=1',
      img: 'https://img.youtube.com/vi/4mpgKuwzVw0/0.jpg',
      class: 'modal3class',
      titel: 'Untertalbach'
    },
    {
      id: 'modal4',
      target: '#modal4',
      frame: 'frame4',
      src: 'https://www.youtube.com/embed/qHnJSvoK8NA?autoplay=1',
      img: 'https://img.youtube.com/vi/qHnJSvoK8NA/0.jpg',
      class: 'modal4class',
      titel: 'Pießling'
    },
    {
      id: 'modal5',
      target: '#modal5',
      frame: 'frame5',
      src: 'https://www.youtube.com/embed/AGKLlizqMxU?autoplay=1',
      img: 'https://img.youtube.com/vi/AGKLlizqMxU/0.jpg',
      class: 'modal5class',
      titel: 'Enns Kummerbrücke'
    },
    {
      id: 'modal6',
      target: '#modal6',
      frame: 'frame6',
      src: 'https://www.youtube.com/embed/Sb-FzpQ2PX0?autoplay=1',
      img: 'https://img.youtube.com/vi/Sb-FzpQ2PX0/0.jpg',
      class: 'modal6class',
      titel: 'Lammer'
    }
  ];

  berichte = [
    {
      id: 'berichtmodal1',
      target: '#berichtmodal1',
      img: 'assets/img/traunfall/traun1.jpg',
      titel: 'Traunfall Erstbefahrung',
      date: '24.04.2019',
      text1:
        'Der Traunfall, eine Perle der Natur, eine Schönheit und die ungezähmte rohe Kraft des Wildwassers. Ob es möglich ist war lange nicht klar. Vielen trieb es die Angst auf die Stirn und doch, einer hat ihn bezwungen...',
      text2:
        '...unser geliebter Thomas Rudelsdorfer. Es dürfte sich um eine Erstbefahrung handeln. Eine Traumleistung und unsere Gratulation. Für uns ein Idol und sehr guter Freund.',
      img1: 'assets/img/traunfall/traun2.jpg',
      img2: 'assets/img/traunfall/traun3.jpg'
    },
    {
      id: 'berichtmodal2',
      target: '#berichtmodal2',
      img: 'assets/img/canyoning/canyoning1.jpg',
      titel: 'Conyoning Friaul',
      date: 'Juni 2017',
      text1:
        'Canyoning, den Fluss mal von der anderen Seite betrachten. Stärkt den Mannschaftsgeist und ist gut für die Seele.',
      text2:
        'Danke für die vielen schönen Erlebnisse und mögen noch mehr davon kommen.',
      img1: 'assets/img/canyoning/canyoning2.jpg',
      img2: 'assets/img/canyoning/canyoning3.jpg'
    },
    {
      id: 'berichtmodal3',
      target: '#berichtmodal3',
      img: 'assets/img/knoten/knoten1.jpg',
      titel: 'Knotenkunde und Sicherheit',
      date: 'Mai 2019',
      text1:
        'Ausrüstungscheck, Umgang mit dem Wurfsack, Knotenkunde und weitere Sicherheitsaspekte.',
      text2:
        'Gemeinsam mit dem Kenterrollentraining und Erste Hilfe festigt es die Sicherheit für uns am Bach!',
      img1: 'assets/img/knoten/knoten2.jpg',
      img2: 'assets/img/knoten/knoten3.jpg'
    }
  ];

  constructor(private _eventService: EventService, private spinner: NgxSpinnerService) {}

  ngOnInit() {
/*     this._eventService.getEvents().subscribe(
      res => (this.events = res),
      err => console.log(err)
    ); */
    this.events = events;
    this.spinner.show();
    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 2000);
    if (window.FB && window.FB.init) {
      window.FB.init({ status: true, xfbml: true, version: 'v5.0' });
    }
  }

  ngAfterViewInit() {
    this.videos.forEach(element => {
      let url = $('.' + element.class).attr('src');

      $('.' + element.class).attr('src', '');
      $(element.target).on('shown.bs.modal', function() {
        $('.' + element.class).attr('src', element.src);
      });
      $(element.target).on('hide.bs.modal', function() {
        $('.' + element.class).attr('src', '');
      });
    });
  }
}
