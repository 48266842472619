<!-- Navigation -->
<nav class="navbar navbar-expand-lg navbar-dark bg-dark static-top">
  <div class="container">
    <a class="navbar-brand" href="#">
      <img
        src="./assets/icon/wfv.png"
        alt="Logo WFV"
        style="max-height: 45px;"
      />
    </a>
    <span class="navbar-text">
      <h3>Welser Faltbootverein</h3>
    </span>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarResponsive"
      aria-controls="navbarResponsive"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarResponsive">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <h5>
            <a class="nav-link" routerLink="/verein" routerLinkActive="active">
              Verein
            </a>
          </h5>
        </li>

        <li class="nav-item">
          <h5>
            <a class="nav-link" routerLink="/aktuell" routerLinkActive="active">
              Aktuell
            </a>
          </h5>
        </li>

        <li class="nav-item">
          <h5>
            <a class="nav-link" routerLink="/pegel" routerLinkActive="active">
              Pegel
            </a>
          </h5>
        </li>

        <li class="nav-item">
          <h5>
            <a class="nav-link" routerLink="/kontakt" routerLinkActive="active">
              Kontakt
            </a>
          </h5> 
        </li>

        <h5>
          <div class="dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="dropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
              Downloads
              </a>

            <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
              <a class="dropdown-item" href="assets\formulare\WFV-Fahrtenprogramm.pdf" download style="text-decoration: none;">                
                Fahrtenprogramm
              </a>
              <a class="dropdown-item" href="assets\formulare\WFV-Beitrittserklaerung.pdf" download style="text-decoration: none;">
                Beitrittserklärung
              </a>
              <a class="dropdown-item" href="assets\formulare\Notfallkarte.pdf" download style="text-decoration: none;">
                Notfallkarte Erste Hilfe
              </a>
            </div>
          </div>
        </h5>

        <li class="nav-item">
          <h5>
          <a
            class="nav-link"
            *ngIf="_authService.loggedIn()"
            routerLink="/members"
            routerLinkActive="active"
            >Mitglieder</a>
          </h5>
        </li>
        <li class="nav-item">
          <h5>
          <a
            class="nav-link"
            *ngIf="!_authService.loggedIn()"
            routerLink="/login"
            routerLinkActive="active"
            >Login</a>
          </h5>
        </li>
        <li class="nav-item">
          <h5>
          <a
            class="nav-link"
            *ngIf="_authService.loggedIn()"
            (click)="_authService.logoutUser()"
            style="cursor: pointer"
            routerLink="/aktuell"
            >Logout</a>
          </h5>
        </li>
        <!--<li class="nav-item">
            <a class="nav-link" routerLink="/register" routerLinkActive="active">Anmelden</a>
          </li>-->
        <li>
          <h5>
            <a
              href="https://www.facebook.com/WFV-Welser-Faltbootverein-116859331726727/"
              target="_blank"
            >
              <i class="fa fa-facebook-square fa-2x"></i>
            </a>
          </h5>
        </li>
      </ul>
    </div>
  </div>
</nav>
