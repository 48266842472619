import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AktuellComponent } from './aktuell/aktuell.component';
import { VereinComponent } from './verein/verein.component';
import { KontaktComponent } from './kontakt/kontakt.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { MembersComponent } from './members/members.component';
import { HomeComponent } from './home/home.component';
import { AuthGuard } from './auth.guard';
import { PegelComponent } from './pegel/pegel.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'aktuell',
    component: AktuellComponent
  },
  {
    path: 'pegel',
    component: PegelComponent
  },
  {
    path: 'verein',
    component: VereinComponent
  },
  {
    path: 'kontakt',
    component: KontaktComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'register',
    component: RegisterComponent
  },
  {
    path: 'members',
    component: MembersComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
