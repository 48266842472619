<div class="container pt-3 fix-footer">
  <div class="card text-center">
    <div class="card-header">
      <h3 class="mb-0">Pegel</h3>
    </div>
    <div class="card-body">
      <div class="row featurette">
        <div class="col-md-12 mx-auto">
          <p>sponsored by
            <a href="https://www.sobos.at/app_download.html" target="_blank"><img src="assets/sponsors/Pegelalarm_Logo.png" alt="Pegelalarm_Logo" width=250 height=auto></a>
            <a href="https://www.sobos.at/app_download.html" target="_blank"><img src="assets/sponsors/google-play-badge.png" alt="Google PlayStore" width=120 height=auto/></a>
            <a href="https://www.sobos.at/app_download.html" target="_blank"><img src="assets/sponsors/ios-app-store.png" alt="Apple App Store" width=104 height=auto/></a>
          </p>
        </div>
      </div>
      <hr>
      <div class="row featurette">
        <div class="col-md-6 mx-auto">
          <form>
            <div class="form-group">
              <label for="sel1">Wähle einen Fluss aus:</label>
              <select class="form-control" (change)="onItemChange($event.target.value)" id="sel1">
                <option *ngFor="let river of rivers" [value]="river.id">
                  {{river.name}}
                </option>
              </select>
            </div>
          </form>
        </div>
        <div class="col-md-12 pt-3">
          <iframe name="chartframe" id="chartframe" [src]="selectedId | safe" style="border:0px" width="100%" height="305px"> </iframe>
        </div>
      </div>
      <hr>
      <div class="row featurette">
        <div class="col-md-12">
          <p>
            Diese Seite enthält Wasserstandinfos unserer oft befahrenen Flüsse und Links zu Pegelseiten. Die angezeigten Messwerte sind von uns ungeprüfte Rohdaten. Ein Klick auf den Fluss öffnet die Seite des Pegelbetreibers.
            Der WFV - Welser Faltboot Verein übernimmt keine Gewähr für die Richtigkeit und Genauigkeit der dargestellten Daten, Gewährleistungs- und Haftungsansprüche werden ausdrücklich ausgeschlossen. 
          </p>
        </div>
      </div>
    </div>
    <div class="card-footer text-muted">
    </div>
  </div>
</div>
