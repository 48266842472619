<div class="container pt-3 fix-footer">
  <!-- Nav tabs -->
  <ul class="nav nav-tabs justify-content-center">
    <li class="nav-item">
      <a class="nav-link active" data-toggle="tab" href="#eintragen">Event eintragen</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" data-toggle="tab" href="#meine">Meine Events</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" data-toggle="tab" href="#alle">Alle Events</a>
    </li>
  </ul>

  <!-- Tab panes -->
  <div class="tab-content pt-3">
    <div class="tab-pane container active" id="eintragen">
    ...
    </div>
    
    <div class="tab-pane container fade" id="meine">
      meine berichte
    </div>

    <div class="tab-pane container fade" id="alle">
      alle berichte
    </div>
  </div>
</div>
