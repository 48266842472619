<div class="container pt-3 fix-footer">
  <div class="card">
    <div class="card-header text-center">
      <h3 class="mb-0">Verein</h3>
    </div>
    <div class="card-body">
      <div class="container">
        <!-- Nav pills -->
        <ul class="nav nav-tabs justify-content-center">
          <li class="nav-item">
            <a class="nav-link active" data-toggle="pill" href="#ueberuns"
              ><i class="fa fa-users"></i> Über uns</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="pill" href="#bootshaus"
              ><i class="fa fa-home"></i> Das Bootshaus</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="pill" href="#fragen"
              ><i class="fa fa-question-circle"></i> Fragen & Antworten</a
            >
          </li>
        </ul>

        <!-- Tab panes -->
        <div class="tab-content">
          <div class="tab-pane container active" id="ueberuns">
            <div class="container marketing pt-3">    
              <!-- START THE FEATURETTES -->
              <div class="row featurette">
                <div class="col-md-7 my-auto">
                  <h2 class="featurette-heading">Der Welser Faltbootverein<span class="text-muted" style="font-size:small"> (seit 1912)</span></h2>

                  <p class="card-text">
                    bietet allen Interessierten die Möglichkeit, den Kajak- und Kanusport auszuüben und gemeinsam mit Freunden, Flüsse und Bäche zu befahren. Wir betreiben den Kajaksport als Breitensport und organisieren gemeinsame Ausfahrten, Kenterrollentrainings im Hallenbad Gunskirchen, sowie Trainingsfahrten auf der Traun und Flüssen in der Umgebung. In unserem Verein, mit seinen mehr als hundert Mitgliedern, findet ihr andere Paddlerinnen und Paddler für gemeinsame Befahrungen.
                  </p>
                </div>
                <div class="col-md-5 my-auto">
                  <img
                  class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto pt-3"
                  width="auto"
                  height="auto"
                  src="assets\img\vwbus.jpg"
                  role="img"
                  aria-label="Placeholder: 400x400"
                  />
                </div>
              </div>
          
              <hr class="featurette-divider">
          
              <div class="row featurette">
                <div class="col-md-7 order-md-2 my-auto">
                  <p class="card-text">
                    Unsere Leihausrüstung erleichtert in der Anfangsphase den Einstieg. Später beraten unsere Lehrwarte oder andere erfahrene Mitglieder gerne bei der Auswahl des richtigen Materials und geben wertvolle Hinweise zum Erlernen und Optimieren der Technik. Regelmäßig organisieren wir auch Kurse mit verschiedenen Schwerpunkten, oft mit externen Trainern, aber leistbarem Unkostenbeitrag. Das Bootshaus an der Traun bietet Platz zum Einstellen der Boote und ist ein beliebter Treffpunkt für die Mitglieder.
                  </p>         
                </div>
                <div class="col-md-5 order-md-1 my-auto">
                  <img
                  class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto pt-3"
                  width="auto"
                  height="auto"
                  src="assets\img\material.jpg"
                  role="img"
                  aria-label="Placeholder: 400x400"
                  />
                </div>
              </div>
          
              <hr class="featurette-divider">
          
              <div class="row featurette">
                <div class="col-md-7 my-auto">
                  <p class="card-text">
                    Ein gemütlicher Vereinsraum mit Beamer lädt zur Planung von Ausfahrten ein, oder aber einfach zum gemütlichen Beisammensitzen und Besprechen der letzten Befahrung mit Fotos und Filmen. Für Sport und Spaß stehen ein Volleyballfeld, Tischfußball und ein Tischtennis-Tisch zur Verfügung. Mittels großem Griller kann aufkommender Hunger jederzeit gestillt werden.
                  </p>   
                </div>
                <div class="col-md-5 my-auto">
                  <img
                  class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto pt-3"
                  width="auto"
                  height="auto"
                  src="assets\img\bootshaus.jpg"
                  role="img"
                  aria-label="Placeholder: 400x400"
                  />                </div>
              </div>
              <!-- /END THE FEATURETTES -->
          
            </div><!-- /.container -->
          </div>

          <div class="tab-pane container" id="bootshaus">
            <h2 class="card-title"></h2>
            <div class="row featurette">
              <div class="col-md-12">
                <h2 class="featurette-heading">
                  Unser Bootshaus<span class="text-muted"></span>
                </h2>
                <p class="card-text">
                  liegt zentral in Wels, direkt an der Traun. Es gibt die
                  Möglichkeit Boote und Ausrüstung zu lagern und ist ein
                  beliebter Treffpunkt für die Mitglieder. Für Mitglieder wird
                  diverses Vereinsequipment zur Verfügung gestellt. Hier werden
                  Touren geplant und nach erfolgtem Wildwassergenuss, nach Lust
                  und Laune, bei einer Grillerei nachbesprochen. Desweitern
                  laden das BeachVolley-Feld, ein Tischtennistisch und
                  Trainingsmöglichkeiten zu weiteren sportlichen Aktivitäten
                  ein. Für die Kleinsten gibt es einige Spielgeräte und genügend
                  Platz zum Austoben. Für Mitglieder ist die Zufahrt mit dem
                  Auto möglich und es sind ausreichend Parkplätze vorhanden. Wer
                  auf dem Wasser zum Bootshaus fährt, kann direkt an einer Stiege
                  die vor dem Bootshaus ist ein und aussteigen.
                </p>
              </div>
            </div>
            <img
              class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto pt-3"
              width="1200"
              height="400"
              src="assets\img\bootshaus_air.jpeg"
              role="img"
              aria-label="Placeholder: 400x400"
            />
          </div>

          <div class="tab-pane container fade" id="fragen">
            <div class="pt-3" id="accordion">
              <div class="card">
                <div class="card-header" id="headingOne">
                  <h5 class="mb-0">
                    <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      Allgemeines...
                    </button>
                  </h5>
                </div>
                <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                  <div class="card-body">
                    <!-- Inner Accordion -->
                    <div class="accordion pt-3" id="accordionExample">
                      <div *ngFor="let obj of allgemeines, let i = index" class="card">
                        <div class="card-header" id="headingAllgemein{{i}}">
                          <h2 class="mb-0">
                            <button
                              class="btn btn-link collapsed"
                              type="button"
                              data-toggle="collapse"
                              attr.data-target="#allgemein{{i}}"
                              aria-expanded="false"
                              aria-controls="collapseAllgemeines"
                            >
                            {{obj.frage}}
                            </button>
                          </h2>
                        </div>
                        <div
                          id="allgemein{{i}}"
                          class="collapse"
                          attr.aria-labelledby="headingAllgemein{{i}}"
                          data-parent="#accordionExample"
                        >
                          <div class="card-body">
                            {{obj.antwort}}
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- End Inner Accordion -->
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingTwo">
                  <h5 class="mb-0">
                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      Mitgliedschaft...
                    </button>
                  </h5>
                </div>
                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                  <div class="card-body">
                    <!-- Inner Accordion -->
                    <div class="accordion pt-3" id="accordionCollapseTwo">
                      <div *ngFor="let obj of mitgliedschaft, let i = index" class="card">
                        <div class="card-header" id="headingMitgliedschaft{{i}}">
                          <h2 class="mb-0">
                            <button
                              class="btn btn-link collapsed"
                              type="button"
                              data-toggle="collapse"
                              attr.data-target="#mitgliedschaft{{i}}"
                              aria-expanded="false"
                              aria-controls="collapseMitgliedschaft"
                            >
                            {{obj.frage}}
                            </button>
                          </h2>
                        </div>
                        <div
                          id="mitgliedschaft{{i}}"
                          class="collapse"
                          attr.aria-labelledby="headingMitgliedschaft{{i}}"
                          data-parent="#accordionCollapseTwo"
                        >
                          <div class="card-body">
                            {{obj.antwort}}
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- End Inner Accordion -->
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingThree">
                  <h5 class="mb-0">
                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      Boote/Ausrüstung...
                    </button>
                  </h5>
                </div>
                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                  <div class="card-body">
                    <!-- Inner Accordion -->
                    <div class="accordion pt-3" id="accordionCollapseThree">
                      <div *ngFor="let obj of equipment, let i = index" class="card">
                        <div class="card-header" id="headingEquipment{{i}}">
                          <h2 class="mb-0">
                            <button
                              class="btn btn-link collapsed"
                              type="button"
                              data-toggle="collapse"
                              attr.data-target="#equipment{{i}}"
                              aria-expanded="false"
                              aria-controls="collapseEquipment"
                            >
                            {{obj.frage}}
                            </button>
                          </h2>
                        </div>
                        <div
                          id="equipment{{i}}"
                          class="collapse"
                          attr.aria-labelledby="headingEquipment{{i}}"
                          data-parent="#accordionCollapseThree"
                        >
                          <div class="card-body">
                            <div [innerHTML]="obj.antwort"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- End Inner Accordion -->                  
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header" id="headingFour">
                  <h5 class="mb-0">
                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseThree">
                      Schnuppern...
                    </button>
                  </h5>
                </div>
                <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion">
                  <div class="card-body">
                    <!-- Inner Accordion -->
                    <div class="accordion pt-3" id="accordionCollapseFour">
                      <div *ngFor="let obj of schnuppern, let i = index" class="card">
                        <div class="card-header" id="headingSchnuppern{{i}}">
                          <h2 class="mb-0">
                            <button
                              class="btn btn-link collapsed"
                              type="button"
                              data-toggle="collapse"
                              attr.data-target="#schnuppern{{i}}"
                              aria-expanded="false"
                              aria-controls="collapseSchnuppern"
                            >
                            {{obj.frage}}
                            </button>
                          </h2>
                        </div>
                        <div
                          id="schnuppern{{i}}"
                          class="collapse"
                          attr.aria-labelledby="headingSchnuppern{{i}}"
                          data-parent="#accordionCollapseFour"
                        >
                          <div class="card-body">
                            <div [innerHTML]="obj.antwort"></div>                            
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- End Inner Accordion -->  
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header" id="headingFive">
                  <h5 class="mb-0">
                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseThree">
                      Kenterrollentraining...
                    </button>
                  </h5>
                </div>
                <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordion">
                  <div class="card-body">
                    <!-- Inner Accordion -->
                    <div class="accordion pt-3" id="accordionCollapseFive">
                      <div *ngFor="let obj of training, let i = index" class="card">
                        <div class="card-header" id="headingTraining{{i}}">
                          <h2 class="mb-0">
                            <button
                              class="btn btn-link collapsed"
                              type="button"
                              data-toggle="collapse"
                              attr.data-target="#training{{i}}"
                              aria-expanded="false"
                              aria-controls="collapseTraining"
                            >
                            {{obj.frage}}
                            </button>
                          </h2>
                        </div>
                        <div
                          id="training{{i}}"
                          class="collapse"
                          attr.aria-labelledby="headingTraining{{i}}"
                          data-parent="#accordionCollapseFive"
                        >
                          <div class="card-body">
                            <div [innerHTML]="obj.antwort"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- End Inner Accordion -->  
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header" id="headingSix">
                  <h5 class="mb-0">
                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseThree">
                      Sicherheit...
                    </button>
                  </h5>
                </div>
                <div id="collapseSix" class="collapse" aria-labelledby="headingSix" data-parent="#accordion">
                  <div class="card-body">
                    <!-- Inner Accordion -->
                    <div class="accordion pt-3" id="accordionCollapseSix">
                      <div *ngFor="let obj of safety, let i = index" class="card">
                        <div class="card-header" id="headingSafety{{i}}">
                          <h2 class="mb-0">
                            <button
                              class="btn btn-link collapsed"
                              type="button"
                              data-toggle="collapse"
                              attr.data-target="#safety{{i}}"
                              aria-expanded="false"
                              aria-controls="collapseSafety"
                            >
                            {{obj.frage}}
                            </button>
                          </h2>
                        </div>
                        <div
                          id="safety{{i}}"
                          class="collapse"
                          attr.aria-labelledby="headingSafety{{i}}"
                          data-parent="#accordionCollapseSix"
                        >
                          <div class="card-body">
                            {{obj.antwort}}
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- End Inner Accordion --> 
                  </div>
                </div>
              </div>
            </div>
            <br>
            <p class="subtitle">
              Haftungsausschluss:
              Der WFV - Welser Faltboot Verein erstellt die Inhalte seiner Internetseiten mit großer Sorgfalt und überprüft bzw. aktualisiert die Inhalte regelmäßig. Dennoch kann der WFV für die Aktualität, Richtigkeit und Vollständigkeit der Inhalte keine Gewähr übernehmen. Alle Inhalte dienen nur der unverbindlichen, allgemeinen Information, die Nutzung der Inhalte der Internetseiten erfolgt auf eigene Gefahr der Besucher. Diese Seiten beinhalten auch Verweise (Links) zu externen Internetseiten Dritter, auf deren Inhalte der WFV keinen Einfluss hat und daher keine Gewähr übernimmt. Sollten Rechtsverletzungen in Verbindung mit externen Internetseiten erkennbar werden, werden die Verweise selbstverständlich umgehend entfernt.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer text-muted"></div>
  </div>
</div>
