import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root"
})
export class AuthService {
  private _registerUrl = "https://test.kajaksport.at:8091/wfv/register";
  private _loginUrl = "https://test.kajaksport.at:8091/wfv/login";

  userName = "";

  constructor(private http: HttpClient, private _router: Router) {}

  registerUser(user) {
    return this.http.post<any>(this._registerUrl, user);
  }

  loginUser(user) {
    return this.http.post<any>(this._loginUrl, user).subscribe(
      res => {
        localStorage.setItem("token", res.token);
        localStorage.setItem("username", res.username);
        this.setUserName(res.username);
        this._router.navigate(["/members"]);
      },
      err => console.log(err)
    );
  }

  logoutUser() {
    this.userName = "";
    localStorage.removeItem("token");
    localStorage.removeItem("username");
  }

  loggedIn() {
    return !!localStorage.getItem("token");
  }

  getToken() {
    return localStorage.getItem("token");
  }

  setUserName(username) {
    if (username !== "") {
      this.userName = username;
    }
  }

  getUserName() {
    if (this.userName !== "") {
      return this.userName;
    } else return localStorage.getItem("username");
  }
}
