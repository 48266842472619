<div class="container pt-3 fix-footer">
  <!-- Nav pills -->
  <ul class="nav nav-pills justify-content-center">
    <li class="nav-item">
      <a class="nav-link active" data-toggle="pill" href="#fahrtenbuch"><i class="fa fa-book"></i> Fahrtenbuch</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" data-toggle="pill" href="#berichte"><i class="fa fa-edit"></i> Berichte</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" data-toggle="pill" href="#events"><i class="fa fa-calendar"></i> Events</a>
    </li>
  </ul>

  <!-- Tab panes -->
  <div class="tab-content">
    <div class="tab-pane container active" id="fahrtenbuch">
      <app-fahrtenbuch></app-fahrtenbuch>
    </div>
    <div class="tab-pane container fade" id="berichte">
      <app-berichte></app-berichte>
    </div>
    <div class="tab-pane container fade" id="events">
      <app-events></app-events>
    </div>
  </div>
</div>