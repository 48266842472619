import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
@Component({
  selector: 'app-pegel',
  templateUrl: './pegel.component.html',
  styleUrls: ['./pegel.component.css']
})
export class PegelComponent implements OnInit {

  rivers = [
    {
      name: 'Traun, Wels',
      id:'https://pegelalarm.at/paw/chart.html?commonid=206391-at'
    },
    {
      name: 'Koppentraun, Obertraun',
      id:'https://pegelalarm.at/paw/chart.html?commonid=205104-at'
    },
    {
      name: 'Alm, Penningersteg',
      id:'https://pegelalarm.at/paw/chart.html?commonid=205518-at'
    },
    {
      name: 'Enns,Admont (Kummerbrücke)',
      id:'https://pegelalarm.at/paw/chart.html?commonid=210823-at'
    },
    {
      name: 'Große Rodl, Zwettl',
      id:'https://pegelalarm.at/paw/chart.html?commonid=206656-at'
    },
    {
      name: 'Lammer, Obergäu',
      id:'https://pegelalarm.at/paw/chart.html?commonid=203307-at'
    },
    {
      name: 'Saalach, Unterjettenberg',
      id:'https://pegelalarm.at/paw/chart.html?commonid=18642003-de'
    },
    {
      name: 'Salza, Wildalpen',
      id:'https://pegelalarm.at/paw/chart.html?commonid=210898-at'
    },
    {
      name: 'Obere Steyr, Kniewas',
      id:'https://pegelalarm.at/paw/chart.html?commonid=205799-at'
    },
    {
      name: 'Untere Steyr, Klaus',
      id:'https://pegelalarm.at/paw/chart.html?commonid=205864-at'
    },
    {
      name: 'Soca, Kobarid',
      id:'https://pegelalarm.at/paw/chart.html?commonid=8080-si'
    }
  ];
  selectedId:SafeHtml = 'https://pegelalarm.at/paw/chart.html?commonid=206391-at';
  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit() {
  }
  onItemChange(selectedId:any){
    this.selectedId = selectedId;
  }

}
